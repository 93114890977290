// NG-SELECT OVERRIDES

.ng-select.ui-select {
  &.ng-select-single {
    .ng-select-container {
      height: $select-height;
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        flex-wrap: nowrap;
        width: 80%;
        overflow: hidden;

        .ng-value {
          margin-bottom: 0;
          margin-right: 0;
          background-color: transparent;
          border: none;

          .ng-value-label {
            padding-left: 0;

            &::after {
              content: ",";
            }
          }

          .ng-value-icon {
            display: none;
          }
        }

        .ng-placeholder {
          top: $sp-18;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          position: relative;

          &::after {
            position: absolute;
            bottom: 0;
            left: $sp-32;
            display: block;
            content: "";
            height: 1px;
            width: calc(100% - (#{$sp-32} * 2));
            background-color: $color-bg-horizontal-line;
          }

          .ng-option-label {
            position: relative;
            padding-left: $sp-50;

            &::after {
              position: absolute;
              left: 0;
              top: -2px;
              display: block;
              content: "";
              width: rem(26px);
              height: rem(26px);
              border: 1px solid $grey;
              border-radius: 50%;
              background-color: transparent;
            }

            &::before {
              position: absolute;
              display: block;
              content: "";
              top: $sp-8;
              left: $sp-8;
              width: rem(13px);
              height: rem(9px);
              mask: url("/assets/icons/check.svg") no-repeat 100% 100%;
              mask-size: cover;
              background-color: $grey;
            }
          }

          &.ng-option-marked,
          &.ng-option-selected {
            .ng-option-label {
              position: relative;

              &::after {
                border: 1px solid $color-bg-primary;
              }

              &::before {
                background-color: $color-bg-primary;
              }
            }
          }
        }

        .ng-option:last-child::after {
          display: none;
        }
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    & > .ng-select-container {
      border: none;
      box-shadow: none;

      @include outline;
    }
  }

  .ng-select-container {
    border: none;
    border-radius: $select-border-radius;
    min-height: $select-height;

    .ng-arrow-wrapper {
      $icon-path: "/assets/icons/triangle-down.svg";

      width: $select-arrow-size;
      height: $select-arrow-size;
      margin-right: 28px;
      mask: url($icon-path) no-repeat 100% 100%;
      mask-size: cover;
      background-color: $color-bg-primary;

      .ng-arrow {
        border-color: transparent;
        border-style: none;
        border-width: 0;
      }
    }

    .ng-value-container {
      padding: $sp-16 $sp-32;

      .ng-input {
        padding-left: $sp-32;
        top: 15px;

        & > input {
          @include font-400-20;
        }
      }

      .ng-placeholder {
        @include font-400-20;

        overflow: hidden;
        text-wrap: nowrap;
        width: 75%;
        text-overflow: ellipsis;
        color: $color-text-select-placeholder;
      }

      .ng-value {
        &-label {
          color: $color-text-select;

          @include font-400-20;
        }
      }
    }
  }

  &.ng-select-opened {
    &.ng-select-bottom {
      & > .ng-select-container {
        min-height: $select-height-opened;
        border-radius: $select-border-radius-opened;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;

        &::after {
          display: block;
          content: "";
          position: absolute;
          bottom: 1px;
          left: $sp-32;
          height: 1px;
          width: calc(100% - (#{$sp-32} * 2));
          background-color: $color-bg-horizontal-line;
        }

        .ng-value-container {
          .ng-input {
            top: $sp-32;
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          .ng-placeholder {
            top: $sp-30;
          }
        }
      }
    }
  }

  .ng-dropdown-panel.ng-select-bottom,
  .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: $select-border-radius-opened;
    border-bottom-left-radius: $select-border-radius-opened;
  }

  .ng-dropdown-panel.ng-select-top,
  .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-top-right-radius: $select-border-radius-opened;
    border-top-left-radius: $select-border-radius-opened;
  }

  &.ng-select-opened.ng-select-top > .ng-select-container {
    min-height: $select-height-opened;
    border-radius: $select-border-radius-opened;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    position: relative;

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 1px;
      left: $sp-32;
      height: 1px;
      width: calc(100% - (#{$sp-32} * 2));
      background-color: $color-bg-horizontal-line;
    }

    .ng-value-container {
      .ng-input {
        top: $sp-32;
      }
    }
  }

  .ng-dropdown-panel {
    border: none;
    box-shadow: none;
    overflow: hidden;
    padding: $sp-16 0;

    .ng-dropdown-panel-items {
      max-height: $select-dropdown-max-height;

      .ng-option {
        padding: $sp-16 $sp-32;
        color: $color-text-select;

        @include font-400-20;

        &:hover {
          font-weight: $font-weight-600;
        }

        &.ng-option-marked,
        &.ng-option-selected {
          background-color: transparent;

          @include font-600-20;
        }
      }
    }
  }

  &.invalid {
    .ng-select-container {
      border: 3px solid $color-state-danger;

      .ng-arrow-wrapper {
        background-color: $color-state-error;
      }

      .ng-placeholder {
        color: $color-state-error;
      }
    }

    .ng-dropdown-panel.ng-select-bottom,
    .ng-dropdown-panel.ng-select-top {
      border: 3px solid $color-state-danger;
    }

    &.ng-select-opened {
      .ng-select-container {
        border-bottom: none;
      }

      .ng-dropdown-panel.ng-select-bottom {
        border-top: none;
      }

      .ng-dropdown-panel.ng-select-top {
        border-bottom: none;
      }

      &.ng-select-top {
        & > .ng-select-container {
          border-top: none;
          border-bottom: 3px solid $color-state-danger;
        }
      }
    }
  }
}
