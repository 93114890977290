$white: #fff;
$lavender: #c1b9e2;
$periwinkle: #d8d3ed;
$dove: #e8e5f4;
$purple-300: #3b298f;
$purple-250: #322c4e;
$purple-200: #4f3da3;
$purple-100: #6351b7;
$purple-50: #8274c5;
$blue: #4095e9;
$orange-200: #f77;
$grey: #cbcbcb;

// General colors
$brand-primary: #ff3f3f;
$brand-secondary: #30d46c;

// General state
$color-state-success: $brand-secondary;
$color-state-danger: $orange-200;
$color-state-error: $brand-primary;
$color-state-info: $purple-100;

// Semantic colors, could be extended
$color-bg-primary: $purple-100;
$color-text-primary: $white;
$color-bg-icon: $white;

// Buttons
$color-bg-button: $white;
$color-text-button: $purple-100;
$color-text-hovered-button: $purple-200;
$color-text-pressed-button: $purple-300;

// Select
$color-text-select-placeholder: $lavender;
$color-text-select: $purple-300;

// Horizontal line
$color-bg-horizontal-line: $lavender;

// Outline
$color-bg-outline: $blue;

// Checkbox
$color-bg-checkbox: $white;
$color-border-checkbox: $white;
$color-icon-checkbox: $purple-100;

// Controls
$color-text-control-placeholder: $lavender;
$color-text-control: $purple-200;

// Gradient
$main-gradient: linear-gradient(
  90deg,
  #a4fefe 0%,
  #e0f9fe 12%,
  #a0fefa 23.5%,
  #ecc4ff 35.5%,
  #f8fec0 52%,
  #bdfeea 64%,
  #dcfed9 76%,
  #acfafa 87.5%,
  #fba5fe 100%
);
$schema-gradient-right: linear-gradient(270deg, #a39bc4 0%, #8e83c4 48%, #8274c5 88%);
$schema-gradient-left: linear-gradient(270deg, #8274c5 0%, #8e83c4 48%, #a39bc4 88%);

// Toast
$color-bg-toast: $purple-250;
$color-toast-text: $dove;
$color-toast-title: $white;
$shadow-toast: 0 8px 12px 0 rgb(0 0 0 / 6%);

// Auth
$color-auth: $white;
$color-auth-check: $purple-300;
