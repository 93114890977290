.control {
  @include font-400-20;

  border-radius: $border-radius-control;
  background-color: $white;
  padding: rem(13px) $sp-32;
  color: $color-text-control;
  caret-color: $color-text-control;
  border: 3px solid transparent;
  font-family: $base-font-family;

  &::placeholder {
    color: $color-text-control-placeholder;
  }

  &:focus {
    @include outline;
  }

  &:read-only {
    pointer-events: none;
  }

  + .control-icon {
    cursor: pointer;
    position: absolute;
    right: $sp-32;
    top: calc(50%);
    transform: translateY(-50%);
    color: $color-text-control;
  }

  &_with-icon {
    padding-right: calc($sp-32 + $icon-size-control);
  }

  &_invalid {
    @include invalid-control;
  }

  &_error-message {
    @include font-600-14;

    color: $color-state-danger;
    padding-top: $sp-8;
    padding-left: $sp-32;
  }
}
