@import "global";
@import "@angular/cdk/overlay-prebuilt.css";

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  background-image: url("/assets/body-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $color-bg-primary;
}
