// -------------------------------------
// Custom variables
// -------------------------------------

// controls
$border-radius-control: rem(48px);
$icon-size-control: rem(24px);

// border radius
$border-radius-0: rem(2px);
$border-radius-1: rem(4px);
$border-radius-2: rem(8px);
$border-radius-3: rem(16px);

// scrollbar
$scrollbar-horizontal-height: 10px;
$scrollbar-vertical-width: 6px;

// button
$border-radius-button: rem(64px);

// select
$select-dropdown-max-height: rem(326px);
$select-height: rem(56px);
$select-height-opened: rem(88px);
$select-border-radius: rem(48px);
$select-border-radius-opened: rem(32px);
$select-arrow-size: rem(26px);

// logo
$logo-width: rem(130px);

// auth
$auth-content-tablet-plus-width: 40%;
$auth-content-mobile-width: 80%;
$auth-content-mobile-margin-from-top: 200px;

// footer
$footer-logo-width: rem(100px);
$footer-logo-width-mobile: rem(68px);

// schemas
$schema-item-width: rem(91px);
$schema-angle-width: rem(36px);
$schema-item-border-radius: rem(50px);
$schema-item-left-clip-path: polygon(
  100% 95.408%,
  100% 95.408%,
  99.99% 96.153%,
  99.962% 96.859%,
  99.917% 97.518%,
  99.857% 98.12%,
  99.782% 98.655%,
  99.696% 99.114%,
  99.598% 99.487%,
  99.492% 99.766%,
  99.377% 99.94%,
  99.257% 100%,
  5.777% 100%,
  5.777% 100%,
  4.84% 99.533%,
  3.951% 98.18%,
  3.122% 96.016%,
  2.365% 93.113%,
  1.692% 89.546%,
  1.115% 85.387%,
  0.645% 80.71%,
  0.295% 75.59%,
  0.076% 70.098%,
  0% 64.309%,
  0% 64.309%,
  0.076% 58.521%,
  0.295% 53.029%,
  0.645% 47.908%,
  1.115% 43.232%,
  1.692% 39.073%,
  2.365% 35.505%,
  3.122% 32.603%,
  3.951% 30.438%,
  4.84% 29.086%,
  5.777% 28.619%,
  64.792% 28.619%,
  68.662% 4.71%,
  68.662% 4.71%,
  68.815% 3.84%,
  68.976% 3.053%,
  69.145% 2.353%,
  69.322% 1.74%,
  69.506% 1.216%,
  69.696% 0.783%,
  69.891% 0.443%,
  70.091% 0.198%,
  70.295% 0.05%,
  70.502% 0%,
  99.255% 0%,
  99.255% 0%,
  99.376% 0.06%,
  99.49% 0.234%,
  99.597% 0.513%,
  99.694% 0.886%,
  99.781% 1.345%,
  99.855% 1.88%,
  99.916% 2.482%,
  99.961% 3.141%,
  99.989% 3.847%,
  99.999% 4.592%,
  99.999% 95.416%,
  100% 95.408%
);
$schema-item-right-clip-path: polygon(
  0% 95.408%,
  0% 95.408%,
  0.01% 96.153%,
  0.038% 96.859%,
  0.083% 97.518%,
  0.143% 98.12%,
  0.218% 98.655%,
  0.304% 99.114%,
  0.402% 99.487%,
  0.508% 99.766%,
  0.623% 99.94%,
  0.743% 100%,
  94.223% 100%,
  94.223% 100%,
  95.16% 99.533%,
  96.049% 98.18%,
  96.878% 96.016%,
  97.635% 93.113%,
  98.308% 89.546%,
  98.885% 85.387%,
  99.355% 80.71%,
  99.705% 75.59%,
  99.924% 70.098%,
  100% 64.309%,
  100% 64.309%,
  99.924% 58.521%,
  99.705% 53.029%,
  99.355% 47.908%,
  98.885% 43.232%,
  98.308% 39.073%,
  97.635% 35.505%,
  96.878% 32.603%,
  96.049% 30.438%,
  95.16% 29.086%,
  94.223% 28.619%,
  35.208% 28.619%,
  31.338% 4.71%,
  31.338% 4.71%,
  31.185% 3.84%,
  31.024% 3.053%,
  30.855% 2.353%,
  30.678% 1.74%,
  30.494% 1.216%,
  30.304% 0.783%,
  30.109% 0.443%,
  29.909% 0.198%,
  29.705% 0.05%,
  29.498% 0%,
  0.745% 0%,
  0.745% 0%,
  0.624% 0.06%,
  0.51% 0.234%,
  0.403% 0.513%,
  0.306% 0.886%,
  0.219% 1.345%,
  0.145% 1.88%,
  0.084% 2.482%,
  0.039% 3.141%,
  0.011% 3.847%,
  0.001% 4.592%,
  0.001% 95.416%,
  0% 95.408%
);
$schema-for-everyone-clip-path: polygon(
  82.361% 0%,
  17.637% 0%,
  17.637% 0%,
  17.166% 0.123%,
  16.701% 0.488%,
  16.244% 1.09%,
  15.797% 1.924%,
  15.361% 2.983%,
  14.94% 4.263%,
  14.534% 5.758%,
  14.146% 7.462%,
  13.778% 9.37%,
  13.432% 11.477%,
  0% 100%,
  18.407% 100%,
  21.47% 79.817%,
  21.47% 79.817%,
  21.665% 78.629%,
  21.872% 77.553%,
  22.091% 76.592%,
  22.32% 75.749%,
  22.557% 75.028%,
  22.803% 74.431%,
  23.055% 73.962%,
  23.312% 73.623%,
  23.575% 73.417%,
  23.84% 73.348%,
  76.419% 73.348%,
  76.419% 73.348%,
  76.685% 73.417%,
  76.947% 73.623%,
  77.204% 73.962%,
  77.456% 74.431%,
  77.702% 75.028%,
  77.94% 75.749%,
  78.168% 76.592%,
  78.387% 77.553%,
  78.594% 78.629%,
  78.79% 79.817%,
  81.852% 100%,
  100% 100%,
  86.568% 11.477%,
  86.568% 11.477%,
  86.222% 9.37%,
  85.854% 7.462%,
  85.466% 5.758%,
  85.061% 4.263%,
  84.639% 2.983%,
  84.204% 1.924%,
  83.756% 1.09%,
  83.299% 0.488%,
  82.834% 0.123%,
  82.363% 0%,
  82.361% 0%
);
$schema-for-everyone-clip-path-mobile: polygon(
  0% 25%,
  0% 25%,
  0.025% 20.945%,
  0.098% 17.098%,
  0.215% 13.511%,
  0.371% 10.235%,
  0.563% 7.322%,
  0.787% 4.824%,
  1.039% 2.79%,
  1.315% 1.275%,
  1.611% 0.327%,
  1.923% 0%,
  98.077% 0%,
  98.077% 0%,
  98.389% 0.327%,
  98.685% 1.275%,
  98.961% 2.79%,
  99.213% 4.824%,
  99.437% 7.322%,
  99.629% 10.235%,
  99.785% 13.511%,
  99.902% 17.098%,
  99.975% 20.945%,
  100% 25%,
  100% 25%,
  99.975% 29.055%,
  99.902% 32.902%,
  99.785% 36.489%,
  99.629% 39.765%,
  99.437% 42.678%,
  99.213% 45.176%,
  98.961% 47.21%,
  98.685% 48.725%,
  98.389% 49.673%,
  98.077% 50%,
  88.305% 50%,
  88.305% 50%,
  88.083% 50.136%,
  87.866% 50.539%,
  87.654% 51.199%,
  87.448% 52.109%,
  87.25% 53.261%,
  87.061% 54.645%,
  86.883% 56.254%,
  86.716% 58.08%,
  86.562% 60.112%,
  86.422% 62.345%,
  84.983% 87.655%,
  84.983% 87.655%,
  84.843% 89.888%,
  84.689% 91.92%,
  84.522% 93.746%,
  84.343% 95.355%,
  84.154% 96.739%,
  83.957% 97.891%,
  83.751% 98.801%,
  83.539% 99.461%,
  83.321% 99.864%,
  83.1% 100%,
  17.381% 100%,
  17.381% 100%,
  17.191% 99.9%,
  17.004% 99.604%,
  16.821% 99.117%,
  16.641% 98.442%,
  16.467% 97.585%,
  16.298% 96.55%,
  16.136% 95.341%,
  15.981% 93.964%,
  15.835% 92.423%,
  15.698% 90.722%,
  13.359% 59.278%,
  13.359% 59.278%,
  13.222% 57.577%,
  13.075% 56.036%,
  12.921% 54.659%,
  12.759% 53.45%,
  12.59% 52.415%,
  12.415% 51.558%,
  12.236% 50.883%,
  12.052% 50.396%,
  11.865% 50.1%,
  11.676% 50%,
  1.923% 50%,
  1.923% 50%,
  1.611% 49.673%,
  1.315% 48.725%,
  1.039% 47.21%,
  0.787% 45.176%,
  0.563% 42.678%,
  0.371% 39.765%,
  0.215% 36.489%,
  0.098% 32.902%,
  0.025% 29.055%,
  0% 25%
);
$schema-gradient-clip-path: polygon(
  81.426% 0.005%,
  99.914% 0.005%,
  77.337% 92.509%,
  77.337% 92.509%,
  76.984% 93.843%,
  76.609% 95.052%,
  76.214% 96.131%,
  75.801% 97.078%,
  75.372% 97.888%,
  74.928% 98.559%,
  74.472% 99.087%,
  74.007% 99.469%,
  73.533% 99.7%,
  73.053% 99.778%,
  0% 99.778%,
  0% 53.319%,
  66.999% 53.319%,
  66.999% 53.319%,
  67.269% 53.276%,
  67.536% 53.145%,
  67.798% 52.931%,
  68.055% 52.633%,
  68.305% 52.255%,
  68.547% 51.798%,
  68.781% 51.265%,
  69.003% 50.656%,
  69.215% 49.975%,
  69.414% 49.222%,
  70.357% 45.356%,
  72.533% 36.44%,
  81.427% 0%,
  81.426% 0.005%
);
$schema-gradient-clip-path-mobile: polygon(
  15.112% 5.338%,
  15.112% 5.338%,
  15.233% 4.345%,
  15.358% 3.449%,
  15.486% 2.653%,
  15.619% 1.959%,
  15.754% 1.367%,
  15.892% 0.879%,
  16.032% 0.497%,
  16.174% 0.222%,
  16.317% 0.056%,
  16.462% 0%,
  83.572% 0%,
  83.572% 0%,
  83.711% 0.051%,
  83.848% 0.203%,
  83.984% 0.454%,
  84.118% 0.804%,
  84.251% 1.251%,
  84.381% 1.794%,
  84.508% 2.432%,
  84.632% 3.163%,
  84.753% 3.985%,
  84.871% 4.899%,
  86.489% 18.336%,
  86.489% 18.336%,
  86.648% 19.578%,
  86.813% 20.698%,
  86.982% 21.692%,
  87.155% 22.559%,
  87.332% 23.297%,
  87.512% 23.906%,
  87.695% 24.382%,
  87.88% 24.724%,
  88.067% 24.931%,
  88.255% 25%,
  98.136% 25%,
  98.136% 25%,
  98.439% 25.304%,
  98.725% 26.184%,
  98.993% 27.592%,
  99.237% 29.48%,
  99.454% 31.8%,
  99.64% 34.506%,
  99.792% 37.548%,
  99.905% 40.88%,
  99.976% 44.452%,
  100% 48.218%,
  100% 48.218%,
  99.976% 51.917%,
  99.908% 55.433%,
  99.799% 58.719%,
  99.652% 61.73%,
  99.471% 64.418%,
  99.26% 66.74%,
  99.023% 68.647%,
  98.762% 70.095%,
  98.482% 71.038%,
  98.185% 71.428%,
  88.171% 74.712%,
  88.171% 74.712%,
  87.998% 74.825%,
  87.827% 75.049%,
  87.657% 75.384%,
  87.489% 75.829%,
  87.324% 76.381%,
  87.16% 77.04%,
  87% 77.805%,
  86.843% 78.674%,
  86.689% 79.646%,
  86.54% 80.719%,
  84.843% 93.638%,
  84.843% 93.638%,
  84.731% 94.437%,
  84.616% 95.157%,
  84.499% 95.796%,
  84.379% 96.353%,
  84.256% 96.826%,
  84.132% 97.216%,
  84.006% 97.521%,
  83.879% 97.74%,
  83.751% 97.872%,
  83.622% 97.917%,
  16.41% 97.917%,
  16.41% 97.917%,
  16.275% 97.868%,
  16.141% 97.723%,
  16.008% 97.483%,
  15.877% 97.149%,
  15.747% 96.722%,
  15.62% 96.204%,
  15.495% 95.595%,
  15.373% 94.897%,
  15.254% 94.111%,
  15.139% 93.238%,
  14.14% 85.186%,
  14.14% 85.186%,
  13.888% 83.277%,
  13.629% 81.549%,
  13.363% 80.003%,
  13.092% 78.643%,
  12.815% 77.471%,
  12.533% 76.489%,
  12.247% 75.7%,
  11.958% 75.106%,
  11.666% 74.71%,
  11.372% 74.513%,
  1.815% 71.421%,
  1.815% 71.421%,
  1.519% 71.031%,
  1.238% 70.09%,
  0.978% 68.643%,
  0.74% 66.736%,
  0.529% 64.415%,
  0.348% 61.726%,
  0.201% 58.716%,
  0.092% 55.429%,
  0.024% 51.914%,
  0% 48.214%,
  0% 48.214%,
  0.024% 44.449%,
  0.095% 40.877%,
  0.208% 37.546%,
  0.36% 34.504%,
  0.546% 31.799%,
  0.763% 29.479%,
  1.007% 27.591%,
  1.274% 26.183%,
  1.561% 25.304%,
  1.863% 25%,
  11.178% 25%,
  11.178% 25%,
  11.508% 24.873%,
  11.836% 24.493%,
  12.161% 23.865%,
  12.482% 22.991%,
  12.797% 21.876%,
  13.106% 20.523%,
  13.408% 18.935%,
  13.702% 17.116%,
  13.988% 15.069%,
  14.264% 12.798%,
  15.112% 5.338%
);

// sign-in form
$form-width: rem(640px);
$toast-width: 332px;
