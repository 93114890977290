$sp-0: 0;
$sp-2: rem(2px);
$sp-4: rem(4px);
$sp-6: rem(6px);
$sp-8: rem(8px);
$sp-10: rem(10px);
$sp-12: rem(12px);
$sp-14: rem(14px);
$sp-16: rem(16px);
$sp-18: rem(18px);
$sp-20: rem(20px);
$sp-22: rem(22px);
$sp-24: rem(24px);
$sp-26: rem(26px);
$sp-30: rem(30px);
$sp-50: rem(50px);
$sp-32: rem(32px);
$sp-40: rem(40px);
$sp-46: rem(46px);
$sp-60: rem(60px);
$sp-70: rem(70px);
$sp-80: rem(80px);
$sp-90: rem(90px);
$sp-110: rem(110px);
$sp-120: rem(120px);
$sp-130: rem(130px);
$sp-160: rem(160px);
$spacings: (
  sp-0: $sp-0,
  sp-2: $sp-2,
  sp-4: $sp-4,
  sp-6: $sp-6,
  sp-8: $sp-8,
  sp1-0: $sp-10,
  sp1-2: $sp-12,
  sp1-6: $sp-16,
  sp1-4: $sp-14,
  sp1-8: $sp-18,
  sp2-0: $sp-20,
  sp2-2: $sp-22,
  sp2-4: $sp-24,
  sp2-6: $sp-26,
  sp3-0: $sp-30,
  sp5-0: $sp-50,
  sp3-2: $sp-32,
  sp4-0: $sp-40,
  sp4-6: $sp-46,
  sp6-0: $sp-60,
  sp7-0: $sp-70,
  sp8-0: $sp-80,
  sp9-0: $sp-90,
  sp1-1-0: $sp-110,
  sp1-2-0: $sp-120,
  sp1-3-0: $sp-130,
  sp1-6-0: $sp-160,
);

// -------------------------------------
// Mixin for paddings
// @mixin: padding($top, $right, $bottom, $left)
// -------------------------------------
// usage:
// @import 'spacing'
// @include padding(sp4, 13px, sp16)
// -------------------------------------
@mixin padding($top, $right: false, $bottom: false, $left: false) {
  @if $top {
    @if map-has-key($spacings, $top) {
      padding-top: map-get($spacings, $top);
    } @else {
      padding-top: rem($top);
    }
  }

  @if $right {
    @if map-has-key($spacings, $right) {
      padding-right: map-get($spacings, $right);
    } @else {
      padding-right: rem($right);
    }
  }

  @if $bottom {
    @if map-has-key($spacings, $bottom) {
      padding-bottom: map-get($spacings, $bottom);
    } @else {
      padding-bottom: rem($bottom);
    }
  }

  @if $left {
    @if map-has-key($spacings, $left) {
      padding-left: map-get($spacings, $left);
    } @else {
      padding-left: rem($left);
    }
  }
}

// -------------------------------------
// Mixin for margins
// @mixin: margin($top, $right, $bottom, $left)
// -------------------------------------
// usage:
// @import 'spacing'
// @include margin(sp4, 13px, sp16)
// -------------------------------------
@mixin margin($top, $right: false, $bottom: false, $left: false) {
  @if $top {
    @if map-has-key($spacings, $top) {
      margin-top: map-get($spacings, $top);
    } @else {
      margin-top: rem($top);
    }
  }

  @if $right {
    @if map-has-key($spacings, $right) {
      margin-right: map-get($spacings, $right);
    } @else {
      margin-right: rem($right);
    }
  }

  @if $bottom {
    @if map-has-key($spacings, $bottom) {
      margin-bottom: map-get($spacings, $bottom);
    } @else {
      margin-bottom: rem($bottom);
    }
  }

  @if $left {
    @if map-has-key($spacings, $left) {
      margin-left: map-get($spacings, $left);
    } @else {
      margin-left: rem($left);
    }
  }
}
